<template>
  <div class="edit-warehouse pt-3">
    <div class="warehouse-wrapper">
      <div
        v-if="warehouseId === 'new'"
        class="d-flex justify-content-between mx-3"
      >
        <strong>Add New Warehouse</strong>
        <img
          src="../../assets/SVG/Back.svg"
          width="60px"
          style="cursor: pointer"
          @click="goToViewWarehouse"
        />
      </div>
      <div v-else class="d-flex justify-content-between mx-3">
        <strong>Edit Warehouse</strong>
        <img
          src="../../assets/SVG/Back.svg"
          width="60px"
          style="cursor: pointer"
          @click="goToViewWarehouse"
        />
      </div>

      <b-card class="cards m-2">
        <div class="mb-5" v-if="warehouseId === 'new'">
          <span class="button-groups">
            <span
              id="seller"
              class="warhouse-btns"
              :class="{ activeTab: isTab === 'seller' }"
              @click="changeSalesGraphTab('seller')"
            >
              Seller
            </span>
            <span
              id="marketplaces"
              class="warhouse-btns"
              :class="{ activeTab: isTab == 'marketplace' }"
              @click="changeSalesGraphTab('marketplace')"
            >
              Marketplace
            </span>
          </span>
        </div>
        <div class="row mt-5" v-if="isTab === 'marketplace'">
          <div class="col-md-4">
            <w-dropdown
              :label-text="''"
              :labelId="'marketplace-options'"
              :selectLabel="'Marketplace'"
              :fieldName="'marketplace_options'"
              :selectedValue="selectedMarketplaceId"
              :selectOption="marketPlaceOptions"
              @selectedOption="onSelectMarketplace($event)"
            />
          </div>
          <div class="col-md-4">
            <w-dropdown
              :label-text="''"
              :labelId="'state'"
              :selectLabel="'State'"
              :fieldName="'state'"
              :selectedValue="'All'"
              :selectOption="states"
              @selectedOption="onSelectState($event)"
            />
          </div>
          <div class="col-md-4">
            <w-dropdown
              :label-text="''"
              :labelId="'city'"
              :selectLabel="'City'"
              :fieldName="'city'"
              :selectedValue="'All'"
              :selectOption="cities"
              @selectedOption="onSelectCity($event)"
            />
          </div>
        </div>

        <div class="row" v-if="isTab == 'marketplace'">
          <div class="col-md-6 px-3">
            <label class="draggable-label">Unlinked Warehouses</label>
            <draggable
              class="draggable-area p-2 form-control"
              v-model="unlinkedWarehouses"
              group="people"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                class="draggable-item"
                v-for="(element, i) in unlinkedWarehouses"
                :key="i"
                v-b-popover.hover.html.bottomRight="getPopoverHtml(element)"
              >
                {{ element.warehouseName }}
              </div>
              <!-- TODO: Below default message should be shown if no data came -->
              <!-- <div slot="footer" key="footer" class="draggable-no-value-message" v-if="unlinkedWarehouses.length<1">No unlinked warehouses found!</div> -->
            </draggable>
          </div>
          <div class="col-md-6 px-3">
            <label class="draggable-label">Linked Warehouses</label>
            <draggable
              class="draggable-area p-2 form-control"
              v-model="linkedWarehouses"
              group="people"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                class="draggable-item linked"
                v-for="(element, i) in linkedWarehouses"
                :key="i"
                v-b-popover.hover.html.bottomRight="getPopoverHtml(element)"
              >
                {{ element.warehouseName }}
              </div>
            </draggable>
          </div>
        </div>

        <div v-if="warehouseType === 'SELLER'">
          <div class="row mt-0">
            <div class="col-md-6">
              <w-text-input
                :fieldType="'text'"
                :labelName="'Warehouse Name'"
                :disabled="warehouseNameDisabled"
                :validation="'required|regex_warehouse_name'"
                :fieldName="'Warehouse Name'"
                :defaultValue="warehouseName"
                :labelStyle="'register-label'"
                @textInputChange="textInputChange($event)"
              />
            </div>

            <div class="col-md-6 status-label">
              <w-dropdown
                :label-text="''"
                :labelId="'warehouse-status'"
                :selectLabel="'Status'"
                :fieldName="'Status'"
                :disable="disabledField"
                :selectedValue="warehouseStatus"
                :selectOption="statusList"
                @selectedOption="getWarehouseStatuss($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <w-text-input
                :fieldType="'text'"
                :labelName="'Pin'"
                :validation="'required|regex_zip_code'"
                :fieldName="'pin Code'"
                :disabled="disabledField"
                :defaultValue="pinCode"
                :labelStyle="'register-label'"
                @textInputChange="getWarehousePin"
              />
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <w-text-input
                :fieldType="'text'"
                :labelName="'Address Line 1'"
                :disabled="disabledField"
                :validation="'required'"
                :fieldName="'Address line 1'"
                :defaultValue="addressLine1"
                :labelStyle="'register-label'"
                @textInputChange="getAddress1"
              />
            </div>

            <div class="col-md-6 address_line-2">
              <w-text-input
                :fieldType="'text'"
                :labelName="'Address Line 2'"
                :disabled="disabledField"
                :validation="'required'"
                :fieldName="'Address Line 2'"
                :defaultValue="addressLine2"
                :labelStyle="'register-label'"
                @textInputChange="getAddress2"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <w-text-input
                :fieldType="'text'"
                :labelName="'City'"
                :validation="'required'"
                :fieldName="'City'"
                :disabled="true"
                :defaultValue="city"
                :labelStyle="'register-label'"
                @textInputChange="getWarehouseCity"
              />
            </div>
            <div class="col-md-4">
              <w-text-input
                :fieldType="'text'"
                :labelName="'State'"
                :validation="'required'"
                :fieldName="'State'"
                :disabled="true"
                :defaultValue="state"
                :labelStyle="'register-label'"
              />
            </div>
            <div class="col-md-4">
              <w-text-input
                :fieldType="'text'"
                :labelName="'Country'"
                :validation="'required'"
                :fieldName="'Country'"
                :disabled="true"
                :defaultValue="country"
                :labelStyle="'register-label'"
              />
            </div>
          </div>
        </div>

        <!-- <div class="row">
        <div class="col-md-6">
          <w-text-input
            style="display:inline-block;border-radius: 5px; background-color: #ffffff;"
            :fieldType="'text'"
            :className="'w-75'"
            :labelName="'Pin'"
            :validation="'required|regex_zip_code'"
            :fieldName="'pin Code'"
            :disabled="disabledField"
            :defaultValue="pinCode"
            :labelStyle="'register-label'"
            @textInputChange="getWarehousePin"
          />
        </div>
      </div> -->

        <w-alert
          v-if="alertState.isAlert"
          :alert="alertState.alert"
          :alertMsg="alertState.alertMsg"
          @closeAlert="alertState.isAlert = false"
        />

        <!-- <div class="row">
        <div class="col-md-6">
          <w-text-input
            style="display:inline-block;border-radius: 5px; background-color: #ffffff;"
            :fieldType="'text'"
            :className="'w-75'"
            :labelName="'State'"
            :validation="'required'"
            :fieldName="'State'"
            :disabled="disabledField"
            :defaultValue="state"
            :labelStyle="'register-label'"
            @textInputChange="getWarehouseState"
          />
        </div>
      </div> -->

        <div class="row">
          <div class="col-md-6">
            <w-dropdown
              :label-text="''"
              :labelId="'warehouse-manager'"
              :selectLabel="'Manager'"
              :fieldName="'Manager'"
              :disable="disabledField"
              v-show="isManager"
              :selectedValue="warehouseManagerId"
              :selectOption="managerListOption"
              @selectedOption="getWarehouseManagers"
            />
          </div>
        </div>
        <div class="action-btn">
          <button
            v-if="warehouseId === 'new'"
            class="update-edit-warehouse"
            id="add-warehouse"
            @click="showConfirmModal('addNew')"
            :disabled="
              this.warehouseType === 'SELLER'
                ? isSaveBtnDisabled
                : this.linkedWarehouses.length < 1
            "
          >
            Add Warehouse
          </button>

          <button
            v-else
            class="update-edit-warehouse"
            id="update-warehouse"
            @click="showConfirmModal('edit')"
            :disabled="isSaveBtnDisabled"
          >
            Update Warehouse
          </button>
          <button
            @click="goToViewWarehouse()"
            id="cancel-warehoue"
            class="cancel-edit-warehouse ml-5"
          >
            Cancel
          </button>
        </div>

        <!-- <div class="mt-4">
        <button
          v-if="warehouseId === 'new'"
          class="update-edit-warehouse"
          @click="showConfirmModal('addNew')"
          :disabled="isSaveBtnDisabled"
          >Save</button
        >

        <button
          v-else
          class="update-edit-warehouse"
          @click="showConfirmModal('edit')"
          :disabled="isSaveBtnDisabled"
          >Update</button
        >

        <button
          @click="goToViewWarehouse()"
          class="cancel-edit-warehouse ml-5"
          >Cancel</button
        >
      </div> -->
      </b-card>

      <!-- confirmation modal for error handling -->
      <Modal
        :trigger="alert"
        :title="'HectoCommerce'"
        :centered="true"
        :showModalTable="false"
        :dontCloseWhenClickedOutside="true"
        :dontCloseWhenEscapePressed="true"
        :dontShowCloseIcon="true"
        :modalbuttonStyle="true"
        :alert="alert"
        :alertMsg="alertMsg"
        :alertType="alertType"
        :isConfirmAlert="isConfirmAlert"
        @eventClose="closeModal()"
        @confirmOkEvent="confirmOk"
      />
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import {
  getWarehouseProfileById,
  getWarehouseStatus,
  // getWarehouseType,
  updateWarehouse,
  addWarehouse,
  getMarketplaceWarehouse,
  addMarketPlaceWarehouse,
  getManagerList,
} from "../../Service/WarehouseServiceApi";
import { getChannelType } from "../../Service/ChannelServiceApi";
import { getLocation } from "../../Service/CommonService";
import { EventBus } from "@/main.js";
import Alert from "../../widgets/Alert.vue";
export default {
  components: {
    draggable,
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-alert": Alert,
    Modal,
  },
  data() {
    return {
      unlinkedWarehouses: [],
      linkedWarehouses: [],
      states: [],
      cities: [],
      // selectedMarketplaceId: "55d09b89-c5f6-461d-b355-2f44e83392d9",
      selectedMarketplaceId: "",
      selectedState: "",
      selectedCity: "",
      drag: false,
      companyID: sessionStorage.getItem("companyIds"),
      // for confirmation modal
      alertType: "",
      alert: false,
      isTab: "seller",
      alertMsg: "",
      isConfirmAlert: false,
      isManager: true,

      selected: "A",
      withoutDropdownFilter: "",
      warehouseId: "",
      marketPlaceOptions: [],

      alertState: {
        alert: "",
        alertMsg: "",
        isAlert: "",
      },
      addNew: true,
      // warehouseId: 0,
      warehouseName: "",
      warehouseAddress: {
        warehouseDetails: [],
        addressLine1: "",
        addressLine2: "",
        // city: "",
        // country: "",
        pinCode: "",
        // state: "",
      },
      city: "",
      country: "",
      state: "",
      warehouseManagerId: "",
      warehouseType: "SELLER",
      warehouseStatus: "",
      statusList: [],
      // warehouseList: [],

      managerListOption: [],
    };
  },
  created() {
    this.getManagerList();
    this.getWarehouseStatus();
    // this.getWarehouseType();
    this.getChannelType();
    this.getMarketplaceWarehouse();
  },
  mounted() {
    this.warehouseId = this.$route.params.warehouseId;
    if (this.$route.params.warehouseId != "new")
      this.loadWarehouse(this.$route.params.warehouseId);
  },

  // for disabled save button
  beforeUpdate() {
    this.isSaveBtnDisabled = ![
      this.addressLine1,
      this.addressLine2,
      this.city,
      this.country,
      this.pinCode,
      this.state,
      this.warehouseName,
      // this.warehouseManagerId,
      this.warehouseStatus,
    ].every(Boolean);
  },

  methods: {
    changeSalesGraphTab(tab) {
      this.isTab = tab;
      if (tab == "seller") {
        this.addNew = true;
        this.disabledField = false;
        this.warehouseNameDisabled = false;
        this.managerNew = false;
        this.isManager = true;
        this.warehouseType = "SELLER";
        this.warehouseName = "";
        this.warehouseStatus = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.city = "";
        this.pinCode = "";
        this.country = "";
        this.state = "";
        this.warehouseManagerId = "";
      } else {
        this.disabledField = true;
        this.isManager = false;
        this.warehouseNameDisabled = true;
        this.addNew = true;
        this.managerNew = true;
        this.warehouseType = "MARKETPLACE";
      }
    },

    // back to warehouse page
    backToWarehouse() {
      this.$router.push("/warehouse");
    },
    // get the list of managers
    getManagerList() {
      this.managerListOption = [];
      getManagerList()
        .then((res) => {
          let resp = res || [];
          resp.forEach((el) => {
            this.managerListOption.push({
              text: `${el.firstName} ${el.lastName}`,
              value: el.id,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    checkedForNewWarehouse(event) {
      this.selected = event;
      if (event == "A") {
        this.addNew = true;
        this.disabledField = false;
        this.warehouseNameDisabled = false;
        this.managerNew = false;
        this.isManager = true;
        this.warehouseType = "SELLER";
      }
      this.warehouseName = "";
      this.warehouseStatus = "";
      this.addressLine1 = "";
      this.addressLine2 = "";
      this.city = "";
      this.pinCode = "";
      this.country = "";
      this.state = "";
      this.warehouseManagerId = "";

      this.disabledField = false;
      this.withoutDropdownFilter = event;
    },

    withDropdown(event) {
      if (event == "B") {
        this.disabledField = true;
        this.isManager = false;
        this.warehouseNameDisabled = true;
        this.addNew = true;
        this.managerNew = true;
        this.warehouseType = "MARKETPLACE";
      }
      this.withoutDropdownFilter = event;
    },

    onSelectWarehouse(id) {
      let wareHouseObj = this.marketPlaceOptions.find(
        (el) => el.warehouseId === id
      );
      if (wareHouseObj) {
        // Object.assign(this,...wareHouseObj);
        this.warehouseName = wareHouseObj.warehouseName;
        this.warehouseStatus = wareHouseObj.warehouseStatus;
        this.addressLine1 = wareHouseObj.addressLine1;
        this.addressLine2 = wareHouseObj.addressLine2;
        this.city = wareHouseObj.city;
        this.country = wareHouseObj.country;
        this.pinCode = wareHouseObj.pinCode;
        this.state = wareHouseObj.state;
        this.warehouseManagerId = wareHouseObj.warehouseManagerId;
      }

      this.warehouseIdToAttach = id;
    },

    goToViewWarehouse() {
      this.$router.push({ path: "/warehouse" });
    },

    getWarehouseStatus() {
      this.statusList = [];
      var status = [];

      getWarehouseStatus()
        .then((res) => {
          status = res;
          status.map((element) => {
            this.statusList.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    getChannelType() {
      this.marketPlaceOptions = [];
      var lists = [];
      getChannelType().then((res) => {
        lists = res;
        lists.map((element) => {
          this.marketPlaceOptions.push({
            value: element.marketplaceId,
            text: element.marketplaceName,
            ...element,
          });
        });
      });
    },

    onSelectMarketplace(e) {
      this.selectedMarketplaceId = e;
      this.getMarketplaceWarehouse();
    },
    onSelectState(e) {
      this.selectedState = e;
      this.getMarketplaceWarehouse();
    },
    onSelectCity(e) {
      this.selectedCity = e;
      this.getMarketplaceWarehouse();
    },

    // get add market place dropdown
    getMarketplaceWarehouse() {
      let data = {};
      if (this.selectedMarketplaceId)
        data["marketplaceIdSet"] = [`${this.selectedMarketplaceId}`];
      if (this.selectedState) data["states"] = [`${this.selectedState}`];
      if (this.selectedCity) data["cities"] = [`${this.selectedCity}`];
      getMarketplaceWarehouse({ ...data })
        .then((res) => {
          this.unlinkedWarehouses = res;
          if (!data || Object.keys(data).length === 0) {
            this.states = [];
            this.cities = [];
            res.map((warehouse) => {
              this.states.push({
                value: warehouse.state,
                text: warehouse.state,
              });
              this.cities.push({
                value: warehouse.city,
                text: warehouse.city,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // fetch warehouse detail by particular id when we click on warehouse edit
    loadWarehouse(warehouseId) {
      this.warehouseId = warehouseId;
      // for warehouse name disable
      if (this.warehouseId) {
        this.warehouseNameDisabled = true;
      }
      getWarehouseProfileById(warehouseId, this.companyID)
        .then((res) => {
          this.warehouseName = res.warehouseName;
          this.warehouseStatus = res.warehouseStatus;
          this.addressLine1 = res.addressLine1;
          this.addressLine2 = res.addressLine2;
          this.city = res.city;
          this.pinCode = res.pinCode;
          this.country = res.country;
          this.state = res.state;
          this.warehouseManagerId = res.warehouseManagerId;
          this.warehouseType = res.warehouseType;
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    updateWarehouseDetails() {
      let newWarehouse = {
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        country: this.country,
        pinCode: this.pinCode,
        state: this.state,
        warehouseName: this.warehouseName,
        warehouseManagerId: this.warehouseManagerId,
        warehouseType: this.warehouseType,
        warehouseStatus: this.warehouseStatus,
        warehouseCompanyId: 1,
        warehouseId: this.warehouseId,
      };
      updateWarehouse(newWarehouse, this.companyID)
        .then((res) => {
          this.alertState.alert = "Success";
          this.alertState.alertMsg = "Warehouse Details Updated Successfully.";
          this.alertState.isAlert = true;
          this.goToViewWarehouse();
          setTimeout(() => {
            this.alertState.isAlert = false;
          }, 3000);
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    saveNewWarehouse() {
      // if (this.selected == "B") {
      if (this.warehouseType === "MARKETPLACE") {
        let payload = {
          warehouseIds: this.linkedWarehouses.map((warehouse) => {
            return warehouse.warehouseId;
          }),
        };
        addMarketPlaceWarehouse(payload)
          .then((res) => {
            this.goToViewWarehouse();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      } else {
        let newWarehouse = {
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          city: this.city,
          country: this.country,
          pinCode: this.pinCode,
          state: this.state,
          warehouseName: this.warehouseName,
          warehouseManagerId: this.warehouseManagerId,
          // warehouseType: this.warehouseType,
          warehouseStatus: this.warehouseStatus,
          // warehouseCompanyId: 1,
          // warehouseId: Number(this.warehouseIdToAttach),
        };
        addWarehouse(newWarehouse, this.companyID)
          .then((res) => {
            this.goToViewWarehouse();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.detail;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      }
    },

    textInputChange(event) {
      this.warehouseName = event;
    },
    getWarehouseStatuss(event) {
      this.warehouseStatus = event;
    },
    getAddress1(event) {
      this.addressLine1 = event;
    },
    getAddress2(event) {
      this.addressLine2 = event;
    },
    getWarehouseCity(event) {
      this.city = event;
    },
    getWarehousePin(event) {
      this.pinCode = event;
      if (this.pinCode.length == 6) {
        getLocation(this.pinCode, "IN").then((res) => {
          let resp = res.data.result[0];

          this.city = resp.district;
          this.state = resp.state;
          this.country = "India";
        });
      }
    },

    // getWarehouseCountryName(event) {
    //   this.country = event;
    // },
    // getWarehouseState(event) {
    //   this.state = event;
    // },
    getWarehouseManagers(event) {
      this.warehouseManagerId = event;
    },
    getWareType(event) {
      this.warehouseType = event;
    },

    // for confirmation modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    showConfirmModal(type) {
      this.modalType = type;
      if (this.modalType === "addNew") {
        this.alertMsg = `Are you sure you want to ${
          this.warehouseType === "SELLER"
            ? "add new warehouse"
            : "link selected warehouses"
        }  ?`;
      } else {
        this.alertMsg = "Are you sure you want to update ?";
      }

      this.alert = true;
      // this.alertMsg = "Do you want to exit ?";
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmOk() {
      if (this.modalType === "addNew") {
        this.saveNewWarehouse();
      } else {
        this.updateWarehouseDetails();
      }
    },
    getPopoverHtml(warehouse) {
      const { warehouseName, warehouseStatus, addressLine1, addressLine2, city, state, country, pinCode } = warehouse;
      let html = `
        <div style="min-width:250px;">
          <div style="display:flex; justify-content: space-between;">
            <div style="letter-spacing:2px;font-weight:500">${warehouseName}</div><div style="padding:0 8px; background-color:#4ac38b; border-radius:4px; box-shadow:0 0 5px 2px #00000035; font-size:smaller; letter-spacing:1px; line-height:1.25rem; font-weight:500">${warehouseStatus}</div>
          </div>
          <div style="margin-top:10px; text-transform:capitalize">${addressLine1}, ${addressLine2}, ${city}, ${state}, ${country}, ${pinCode}</div>
        </div>
      `;
      if(warehouseStatus !== 'ACTIVE') html = html.replace('#4ac38b','#fc6538');
      return html;
    }
  },
};
</script>

<style scoped>
.warehouse-wrapper {
  /* margin: 0rem 60px; */
  margin: 0rem 47px 40px 60px;
}
::v-deep .form-control {
  height: 35px !important;
}
/* active tab */
.activeTab {
  background: #2185d0;
  color: #fff !important;
  border-radius: 20px;
}
.button-groups {
  border: 0.8px solid #2185d0;
  border-radius: 26px;
  min-height: 35px;
  padding: 0;
  display: inline-block;
}
.warhouse-btns {
  padding: 8px 35px;
  cursor: pointer;  
  transition: all 0.15s ease-in-out;
}
.button-groups span {
  font-size: 13px;
  font-weight: 600;
  color: #2185d0;
  border: none;
  display: inline-block;
}
.select_warehouse ::v-deep .card-body {
  padding: 5px 0;
}
.select_warehouse ::v-deep .custom-radio label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  color: #1b1c1d;
}
.select_warehouse .warning_icon {
  border: 1px solid #f2943a;
  border-radius: 50%;
  font-size: 7px;
  padding: 1px 4px;
  color: #f2943a;
  position: relative;
  top: 3px;
  margin-left: 3px;
  cursor: pointer;
}
/* .warehouse_label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  color: #1b1c1d;
} */
.edit-card ::v-deep .card-body {
  padding: 15px 0;
}
.edit-warehouse .edit-label {
  font-family: "proxima-nova", sans-serif !important;
  /* font-weight: 600; */
  font-size: 16px;
  color: #1b1c1d;
  /* opacity: 0.8; */
}

.edit-warehouse ::v-deep .select_style label {
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  /* font-weight: 600; */
}
.edit-warehouse .address_line-2 ::v-deep label {
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  /* font-weight: 600; */
}
.action-btn {
  margin-top: 40px;
  text-align: center;
}
.update-edit-warehouse {
  border-radius: 5px;
  border: solid 1px #2e8cd3;
  background-color: #2e8cd3;
  color: #fff;
  padding: 4px 35px;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif !important;
  letter-spacing:0.4px;
}
.update-edit-warehouse:disabled {
  cursor: not-allowed;
  background-color: #9ec7e7;
  border-color: #9ec7e7;
  color: rgb(103, 148, 165);
}
.update-edit-warehouse:disabled:hover{
  background-color: #9ec7e7;
}
.cancel-edit-warehouse {
  border-radius: 5px;
  border: solid 1px #2e8cd3;
  background-color: #ffffff;
  color: #2e8cd3;
  padding: 4px 35px;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif !important;
  letter-spacing:0.4px;
}
.update-edit-warehouse:hover{
  background-color: #267dc0;
}
.cancel-edit-warehouse:hover {
  background-color: #f4fafd;
}
::v-deep .input-group .form-control {
  font-size: 14px;
  color: #767676;
}
/* style for navigation link */
.navigation-link {
  font-family: proxima-nova, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #767676;
}
.status-label ::v-deep label {
  padding-top: 5px;
}

.draggable-label {
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
}

.draggable-area {
  display: flex;
  align-items: flex-start;
  min-height: 250px;
  background-color: transparent;
}

.draggable-item {
  padding: 8px 18px;
  cursor: move;
  margin: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.draggable-no-value-message {
  align-self: center;
  margin: auto;
  color: rgb(138, 138, 138);
  letter-spacing: 0.2px;
}

.linked {
  background-color: #023449;
  color: #ffffff;
}

@media screen and (max-width: 800px) {
  .warehouse-wrapper {
    margin: 0rem 10px;
    margin-top: -40px !important;
  }
  .update-edit-warehouse {
    width: 100%;
  }
  .cancel-edit-warehouse {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 20px;
  }
  .button-groups {
    padding: 5px 0;
  }
}
</style>
