<template>
  <div>
    <div class="form-row px-0 mx-0 select_style">
      <label :class="labelClass" v-if="selectLabel">{{
        $t(selectLabel)
      }}</label>
      <b-form-select
        class="label-text"
        :options="selectOption"
        :name="fieldName"
        :variant="color"
        :value="selectedValue"
        :disabled="disable"
        :id="labelId"
        @change="change($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "selectLabel",
    "selectedValue",
    "selectOption",
    "fieldName",
    "color",
    "labelClass",
    "disable",
    "labelId",
  ],
  data() {
    return {
      InvalideError: false,
    };
  },
  methods: {
    change(event) {
      this.$emit("selectedOption", event);
    },
  },
};
</script>
<style>
.label-text:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
  background-color: #FFFFFF;
}
.select_style .custom-select {
  color: #555;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  cursor: pointer;
  /* box-shadow: 0px 1px 10px #40404029; */
}
.select_style .custom-select > option {
  box-shadow: none;
  padding: 3px 5px;
}
</style>
